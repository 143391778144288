<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <!--<a-col :md="6" :sm="8">-->
            <!--<a-form-item :label="$t(I18N_KEY.ORDER + 'orderNo')">-->
              <!--<a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'orderNo'))" v-model="queryParam.orderNo"></a-input>-->
            <!--</a-form-item>-->
          <!--</a-col>-->

          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'createTime')">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'settleTime')">
              <a-range-picker
                v-model="queryParam.settleTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'overdueDaysStart')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'overdueDays'))" v-model="queryParam.overdueDaysStart"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.department')">
              <a-select
                v-model="queryParam.departmentId"
                @change="onChangeOne"
              >
                <a-select-option v-for="item in departmentList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.grouping')">
              <a-select
                v-model="queryParam.groupId"
              >
                <a-select-option v-for="item in groupList" :key="item.id">
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.collection_stage')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.normalConfigId">
                <a-select-option v-for="item in normalConfigList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('order.applicationDeadline')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.deadlineConfigValue">
                <a-select-option v-for="item in deadlineConfigList" :key="item.itemValue">
                  {{ item.itemText }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.orderType')">
              <a-select
                default-value="0"
                style="width: 190px"
                v-model="queryParam.orderType"
                :options="[
              { value: '', label: '全部' },
              { value: '2', label: '正常订单&提前入催订单' },
              { value: '0', label: '正常订单' },
              { value: '-1', label: '提前入催订单' },
              { value: '1', label: '回捞订单' },
            ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md='8' :sm='8'>
            <a-form-item :label="'产品类型'">
              <a-select mode='multiple'
                        v-model='queryParam.appSsids'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'custType')">
              <a-select default-value="" style="width: 120px" v-model="queryParam.custType">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="1">
                  新户
                </a-select-option>
                <a-select-option value="2">
                  老户
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.stageType')">
              <dict-select
                v-model="queryParam.collectorType"
                :dict-code="DICT_CODE.STAGE_TYPE"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              />
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :rowClassName="rowClassName"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getPerformanceForGroupLock, getSysGroup} from '@/webpublicapi/platformCollection'
import { getCollectionListDepartment } from '@/api/group'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import exportFile from '@/webpublicapi/exportFile'
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
import { getAllDeadlineConfig } from '@/webpublicapi/deadlineConfig'
import { getApplist } from '@/api/order'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      appList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      groupList: [],
      departmentList: [],
      normalConfigList: [],
      deadlineConfigList: [],
      // 查询条件
      queryParam: {
        "endTime" : [moment(),moment()],
        "orderNo":"",
        "aadhaarName":"",
        "mobileNo":"",
        "groupId":"",
        'appSsids': '',
        'custType': '',
        "collectorType": undefined

      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [

        {
          title:  this.$t('platformCollection.department_name'),
          align: 'left',
          dataIndex: 'departmentName'
        },
        {
          title:  this.$t('collection.groupName'),
          align: 'left',
          dataIndex: 'groupName'
        },
        {
          title: this.$t('collection.collectionNumber'),
          align: 'left',
          dataIndex: 'collectionNum'
        },
        {
          title: this.$t('collection.collectionAmount'),
          align: 'left',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t('collection.pendingNum'),
          align: 'left',
          dataIndex: 'pendingNum'
        },
        {
          title: this.$t('collection.processNum'),
          align: 'left',
          dataIndex: 'processNum'
        },
        {
          title: this.$t('collection.cancelNum'),
          align: 'left',
          dataIndex: 'cancelNum'
        },
        {
          title:this.$t('collection.succedNumber'),
          align: 'left',
          dataIndex: 'succedNum'
        },
        {
          title:this.$t('collection.extendNumber'),
          align: 'left',
          dataIndex: 'extendNum'
        },
        {
          title:this.$t('collection.succedAmount'),
          align: 'left',
          dataIndex: 'succedApplyAmt'
        },
        {
          title: this.$t('collection.succedRate'),
          align: 'left',
          dataIndex: 'succedRate',
          sorter: (a, b) => a.departmentName!= '总计'? a.succedRate - b.succedRate: false
        },
        {
          title: this.$t('platformCollection.stageType'),
          align: 'left',
          dataIndex: 'collectorType',
          customRender: function (text) {
            if (text==='1') {
              return "非分期"
            } else if (text==='2') {
              return "分期"
            } else {
              return "";
            }
          }
        },
        {
          title: this.$t('collection.amountPaidAmt'),
          align: 'left',
          dataIndex: 'amountPaidAmt'
        },
        {
          title: this.$t('collection.amountPaidRate'),
          align: 'left',
          dataIndex: 'amountPaidRate',
          sorter: (a, b) => a.departmentName!= '总计'? a.amountPaidRate - b.amountPaidRate: false
        },
        {
          title: this.$t('collection.amountSuccedRate'),
          align: 'left',
          dataIndex: 'amountSuccedRate',
          sorter: (a, b) => a.departmentName!= '总计'?a.amountSuccedRate - b.amountSuccedRate: false
        }
      ]
    },
  },
  created () {
    getApplist().then(res => {
      this.appList = res.data || []
    })
    this.getDict();
    this.loadData();
    getSysGroup().then(res => {
      this.groupList = res.data || [];
    });
    getCollectionListDepartment().then(res => {
      this.departmentList = res.data || [];
    });
    getAllNormalConfig().then(res => {
      this.normalConfigList = res.data || [];
    }),
    getAllDeadlineConfig().then(res => {
      this.deadlineConfigList = res.data || [];
    })
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getPerformanceForGroupLock(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const { settleTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
          settleDateStart: (settleTime && settleTime.length) ? this.transformTime(settleTime[0]) : undefined,
          settleDateEnd : (settleTime && settleTime.length) ? this.transformTime(settleTime[1]) : undefined,
      }
      delete data.endTime;
      delete data.settleTime;
      var param = Object.assign({}, data)
      // param.pageNum = this.form.pageNum;
      // param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;

        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.endTime = undefined;
      that.queryParam.settleTime = undefined;
      that.queryParam.appSsids = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    onChangeOne(value) {
      console.log(value);
      getSysGroup({"departmentId": value}).then(res => {
        this.groupList = res.data || [];
        this.queryParam.groupId = "";
      });
    },
    downLoadData () {
      var params = this.getQueryParams()// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value) + '';
      // console.log(this.exportParams.replaceAll(",", "&"))
      const file = {
        fileUrl: '/api/web/webpublic/platform/collectionTask/getPerformanceForGroupLockExcel?' + this.exportParams.replaceAll(",", "&"),
        fileName: 'report.xls',
      }
      // console.log(file.fileUrl)

      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    rowClassName(record,index){

      if(record.departmentName == '总计'){
        return 'table_weekendColor';
      }
      return '';
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
