<template>
  <div>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline'>
        <a-row :gutter='24'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'taskName')">
              <a-input v-model='form.taskName' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'templateName')">
              <a-input v-model='form.templateName' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'state')">
              <a-select
                v-model='form.taskStatus'
              >
                <a-select-option value='' selected>
                  {{ $t(I18N_KEY.COUPON + 'all') }}
                </a-select-option>
                <a-select-option value='1'>
                  {{ $t(I18N_KEY.COUPON + 'open') }}
                </a-select-option>
                <a-select-option value='2'>
                  {{ $t(I18N_KEY.COUPON + 'close') }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'type')">
              <a-select
                v-model='form.smsType'
              >
                <a-select-option value='' selected>
                  {{ $t(I18N_KEY.COUPON + 'all') }}
                </a-select-option>
                <a-select-option value='1'>
                  {{ $t(I18N_KEY.COUPON + 'SMS') }}
                </a-select-option>
                <a-select-option value='2'>
                  {{ $t(I18N_KEY.COUPON + 'voice') }}
                </a-select-option>
                <a-select-option value='3'>
                  {{ $t(I18N_KEY.COUPON + 'fireBase') }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'crowds')">
              <a-select
                v-model='form.sendGroup'
              >
                <a-select-option value='' selected>
                  {{ $t(I18N_KEY.COUPON + 'all') }}
                </a-select-option>
                <a-select-option v-for='item in sendGroupList' :key='item.id' :value="item.id + ''">
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='search()'>{{ $t(I18N_KEY.COUPON + 'search') }}</a-button>&nbsp;&nbsp;
              <a-button type='primary' @click='searchReset' icon='reload'>{{ $t(I18N_KEY.COUPON + 'reset') }}</a-button>&nbsp;&nbsp;
              <a-button type='primary' @click="viewCoupon('add')">
            <a-icon type='plus' />
            {{ $t(I18N_KEY.COUPON + 'addTask') }}
          </a-button>
            </span>
          </a-col>
        </a-row>
        <a-row>
        </a-row>

      </a-form>
    </div>

    <div class='table-page-search-wrapper'>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :loading='loading'
        bordered>
        <span slot='action' slot-scope='text, record'>
          <a @click="viewCoupon('edit', record)">
            {{ $t(I18N_KEY.COUPON + 'edit') }}
          </a>
        </span>
      </a-table>
    </div>
    <a-modal
      :title='modalTitle'
      v-model='visible'
      @ok='submitForm'
    >
      <a-form :form='basicForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'product')">
          <a-select
            v-model='basicForm.appSsid'
          >
            <a-select-option value=''>
              {{ $t(I18N_KEY.COUPON + 'all') }}
            </a-select-option>
            <a-select-option v-for='item in appList' :key='item.appssid' :value='item.appssid'>
              {{ item.appName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'taskName')">
          <a-input v-model='basicForm.taskName' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'taskStatus')">
          <a-switch v-model='basicForm.taskStatus' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'typeOfSMS')">
          <a-select
            v-model='basicForm.smsType' @change="handleTypeChange"
          >
            <a-select-option value='1' selected>
              {{ $t(I18N_KEY.COUPON + 'SMS') }}
            </a-select-option>
            <a-select-option value='2'>
              {{ $t(I18N_KEY.COUPON + 'voice') }}
            </a-select-option>
            <a-select-option value='3'>
              {{ $t(I18N_KEY.COUPON + 'fireBase') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'SMSTemplates')">
          <a-select
            v-model='basicForm.templateId'
          >
            <a-select-option v-for='item in smsTemplateList' :key='item.id' :value="item.id + ''">
              {{ item.templateName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'sendingTime')">
          <a-select
            v-model='basicForm.sendDateType'
          >
            <a-select-option value='1'>
              {{ $t(I18N_KEY.COUPON + 'everyDay') }}
            </a-select-option>
            <a-select-option value='2'>
              {{ $t(I18N_KEY.COUPON + 'setTime') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="' '" v-show="basicForm.sendDateType === '1'">
          <a-time-picker
            v-model='basicForm.sendDate_time'
            format='HH:mm:ss'
            style='width: 100%;' />
        </a-form-item>
        <a-form-item :label="' '" v-show="basicForm.sendDateType === '2'">
          <a-date-picker
            v-model='basicForm.sendDate_date'
            showTime
            format='YYYY-MM-DD HH:mm:ss'
            style='width: 100%;' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'targetSendingAudience')">
          <a-select
            v-model='basicForm.sendGroup'
          >
            <a-select-option v-for='item in sendGroupList' :key='item.id' :value="item.id + ''">
              {{ item.groupName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'sendingTripartition')" v-show='sendChannelVisible'>
          <a-select
            v-model='basicForm.sendChannel'
          >
            <a-select-option v-for='item in smsChannelList' :key='item.channel' :value='item.channel'>
              {{ item.channelName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { getSmsTaskList, addOrEditSmsTask, listSmsSendGroup, listSmsChannel, listSmsTemplate } from '@/webpublicapi/operation'
import moment from 'moment/moment'
import { getApplist } from '@/webpublicapi/order'


const I18N_KEY = {
  COUPON: 'coupon.',
}
export default {

  data() {
    return {
      I18N_KEY,
      visible: false,
      sendChannelVisible: true,
      modalTitle: undefined,
      appList: [],
      sendGroupList: [],
      smsChannelList: [],
      smsChannelListBak: [],
      smsTemplateList: [],
      smsTemplateVoiceList: [],
      smsTemplateSMSList: [],
      dataSource: [],
      columns: [
        {
          title: this.$t(I18N_KEY.COUPON + 'taskName'),
          align: 'left',
          dataIndex: 'taskName'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'templateName'),
          align: 'left',
          dataIndex: 'templateName'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'taskNode'),
          align: 'left',
          dataIndex: 'sendGroup',
          customRender: (text) => {
            for (let i = 0; i < this.sendGroupList.length; i++) {
              if (this.sendGroupList[i].id + '' === text) {
                return this.sendGroupList[i].groupName
              }
            }
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'sendingMethod'),
          align: 'left',
          dataIndex: 'smsType',
          customRender: (text) => {
            if (text === 1) {
              return this.$t(I18N_KEY.COUPON + 'SMS');
            } else if (text === 2) {
              return this.$t(I18N_KEY.COUPON + 'voice');
            } else if (text === 3) {
              return this.$t(I18N_KEY.COUPON + 'fireBase');
            }
            return '';
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'sendingTime'),
          align: 'left',
          dataIndex: 'sendDate'

        },
        {
          title: this.$t(I18N_KEY.COUPON + 'state'),
          align: 'left',
          dataIndex: 'taskStatus',
          customRender: (text) => {
            return text === '1' ? this.$t(I18N_KEY.COUPON + 'open') : this.$t(I18N_KEY.COUPON + 'close')
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'updateTime'),
          align: 'left',
          dataIndex: 'updatedTime'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'operate'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        taskName: '',
        templateName: '',
        taskStatus: '',
        smsType: '',
        sendGroup: ''
      },
      defaultBasicForm: {
        id: '',
        taskName: '',
        appSsid: '',
        taskStatus: true,
        smsType: '1',
        sendChannel: '',
        templateId: '',
        sendDateType: '1',
        sendDate_date: moment(),
        sendGroup: '',
        sendDate_time: moment()
      },
      basicForm: {},
    }
  },

  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getApplist().then(res => {
        this.appList = []
        this.appInList = res.data || []
        for (var i = 0; i < this.appInList.length; i++) {
          if (this.appInList[i].appssid != '') {
            this.appList.push(this.appInList[i])
          }
        }
      })
      listSmsChannel().then(res => {
        this.smsChannelList = res.data || []
        this.smsChannelListBak = res.data || []
      })
      listSmsSendGroup().then(res => {
        this.sendGroupList = res.data || []
      })
      listSmsTemplate().then(res => {
        this.smsTemplateList = res.data || []
        for (var i = 0; i < this.smsTemplateList.length; i++) {
          if (this.smsTemplateList[i].type === 1)
            this.smsTemplateSMSList.push(this.smsTemplateList[i])
          else
            this.smsTemplateVoiceList.push(this.smsTemplateList[i])
        }
      })
      this.search(this.form)
    },
    searchReset() {
      this.form.taskName = ''
      this.form.templateName = ''
      this.form.taskStatus = ''
      this.form.sendGroup = ''
      this.form.smsType = ''
    },
    handleTypeChange(type)
    {
      if("1" === type)
      {
          this.smsChannelList = this.smsChannelListBak
          this.basicForm.sendChannel = this.smsChannelList[0].channel
          this.smsTemplateList = this.smsTemplateSMSList;
          this.basicForm.templateId = this.smsTemplateList[0].id + ''
      }else {
        this.smsChannelList = [{channelName:"ivr",channel:"ivr"}]
        this.basicForm.sendChannel = "ivr"
        this.smsTemplateList = this.smsTemplateVoiceList;
      }

      if(this.smsTemplateList.length > 0)
        this.basicForm.templateId = this.smsTemplateList[0].id + ''
      else
        this.basicForm.templateId = ''
    },
    search() {

      const form = {
        taskName: this.form.taskName === '' ? undefined : this.form.taskName,
        templateName: this.form.templateName === '' ? undefined : this.form.templateName,
        taskStatus: this.form.taskStatus === '' ? undefined : this.form.taskStatus,
        sendGroup: this.form.sendGroup === '' ? undefined : this.form.sendGroup,
        smsType: this.form.smsType === '' ? undefined : this.form.smsType
      }

      getSmsTaskList(form).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    viewCoupon(mode, coupon = {}) {
      this.visible = true
      this.basicForm.id = coupon.id
      if (mode == 'edit') {

        if(coupon.smsType === '1')
          this.smsTemplateList = this.smsTemplateSMSList
        else
          this.smsTemplateList = this.smsTemplateVoiceList

        this.basicForm = {
          //...this.defaultBasicForm,
          id: coupon.id,
          taskName: coupon.taskName,
          appSsid: coupon.appSsid,
          taskStatus: coupon.taskStatus === '1',
          smsType: coupon.smsType,
          sendChannel: coupon.sendChannel,
          templateId: coupon.templateId + "",
          sendDateType: coupon.sendDateType,
          sendDate: coupon.sendDate,
          sendGroup: coupon.sendGroup,
          sendDate_time: moment(coupon.sendDateType === '1' ? (moment().format('YYYY-MM-DD') + ' ' + coupon.sendDate) : coupon.sendDate),
          sendDate_date: moment(coupon.sendDateType === '1' ? (moment().format('YYYY-MM-DD') + ' ' + coupon.sendDate) : coupon.sendDate)
        }

      } else {
        this.basicForm = { ...this.defaultBasicForm }
        this.smsTemplateList = this.smsTemplateSMSList
        this.smsChannelList = this.smsChannelListBak
        this.basicForm.sendGroup = this.sendGroupList[0].id + ''
        this.basicForm.sendChannel = this.smsChannelList[0].channel
        if(this.smsTemplateList.length > 0)
          this.basicForm.templateId = this.smsTemplateList[0].id + ''
        else
          this.basicForm.templateId = ''
      }
      this.modalTitle = mode == 'add' ? this.$t(I18N_KEY.COUPON + 'addTask') : this.$t(I18N_KEY.COUPON + 'modifyTasks')
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    submitForm() {
      this.visible = false
      this.loading = true
      const form = {
        ...this.basicForm,
        taskStatus: this.basicForm.taskStatus ? 1 : 2,
        sendDate: this.basicForm.sendDateType == 1 ?
          ((this.basicForm.sendDate_time) ? this.transformTime(this.basicForm.sendDate_time) : undefined) :
          ((this.basicForm.sendDate_date) ? this.transformTime(this.basicForm.sendDate_date) : undefined)
      }

      addOrEditSmsTask(form).then(res => {
        this.loading = false
        this.$message.success(this.$t(I18N_KEY.COUPON + 'success'))
        this.search(1)
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    }
  }
}
</script>
